<template>
  <div class="bg-white px-2 w-100 position-fixed" style="bottom: 0;z-index:9999;padding:.7em 0">
    <div class="d-flex flex-nowrap justify-content-center" style="gap:10px 20px">
      <router-link to="/chats" class="d-flex flex-column align-items-center flex-fill text-primary justify-content-center">
        <i class="bx bx-message-dots" style="font-size: 30px;"></i>
        <!-- <div style="color:#1B628C;margin-top:.25em;font-size: 14px;" class="font-weight-bolder">
          Chats
        </div> -->
      </router-link>
      <router-link to="/profile" class="d-flex flex-column align-items-center flex-fill text-primary justify-content-center">
        <i class="bx bx-user " style="font-size: 30px;"></i>
        <!-- <div style="color:#1B628C;margin-top:.25em;font-size: 14px;" class="font-weight-bolder">
          Profile
        </div> -->
      </router-link>
      <router-link v-if="user" :to="user.user_role == 'service_provider' ? '/bussiness' : `/homepage`" class="d-flex flex-column align-items-center flex-fill text-primary justify-content-center">
        <i class="bx bx-home " style="font-size: 30px;"></i>
        <!-- <div style="color:#1B628C;margin-top:.25em;font-size: 14px;" class="font-weight-bolder">
          Home
        </div> -->
      </router-link>
      <router-link to="/activities" class="d-flex flex-column align-items-center flex-fill text-primary justify-content-center">
        <i class="bx bx-calendar" style="font-size: 30px;"></i>
        <!-- <div style="color:#1B628C;margin-top:.25em;font-size: 14px;" class="font-weight-bolder">
          Home
        </div> -->
      </router-link>
      <router-link to="/settings" class="d-flex flex-column align-items-center flex-fill text-primary justify-content-center">
        <i class="bx bx-cog " style="font-size: 30px;"></i>
        <!-- <div style="color:#1B628C;margin-top:.25em;font-size: 14px;" class="font-weight-bolder">
          Settings
        </div> -->
      </router-link>
    </div>
  </div>
</template>

<script> 
import store from '@/store' 

export default { 
    computed: { 
        user(){
            return store.state.auth.USER
        }, 
    }, 
}

</script>

<style lang="scss">
$--simple-drawer-bg-color : rgba(27, 98, 140, 1);
@import "~vue-simple-drawer/src/index";

</style>